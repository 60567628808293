import { IonButton, IonCard, IonCardContent, IonCardTitle, IonCol, IonContent, IonPage, IonRow, isPlatform } from "@ionic/react";
import Header from "../../components/Headers/Header";
import PageTitle from "../../components/PageTitle/PageTitle";
import React from "react";
import {PersonContext} from "../../context/PersonContext";
import WelcomeModal from "../../components/Modals/WelcomeModal";
import VerifyEmailAlert from "../../components/Banners/VerifyEmailBanner";
import InvitationsCard from "../../components/Invitations/InvitationsCard";
import ErrorAlert from "../../components/Errors/ErrorAlert";

interface IProps {
    setOrganizationHandler: Function
};

interface IState {shouldDisplayWelcomeModal: boolean}

class DashboardPage extends React.Component<IProps, IState> {
    static contextType = PersonContext;

    constructor(props: any) {
        super(props);

        this.state = {
            shouldDisplayWelcomeModal: false
        };
        
    }

    async componentDidMount() {
        let hasRoles = !!this.context.roles;
        if (!hasRoles) await this.setState({shouldDisplayWelcomeModal: true});
    }

    async componentDidUpdate() {
        if (!this.state.shouldDisplayWelcomeModal && this.context.roles) {
            let hasRoles = !!this.context.roles;
            if (!hasRoles) await this.setState({shouldDisplayWelcomeModal: true});
        }
    }

    render() {
        const {shouldDisplayWelcomeModal} = this.state;
        return (
            <IonPage id="dashboardPage" className="bg-light">
                <IonContent>
                    {isPlatform("mobile") && <Header setOrganizationHandler={this.props.setOrganizationHandler}/>}

                    {!this.context.id && <ErrorAlert error="A problem occurred and we could not find your data. Please contact hello@ringsidepro.com." />}

                    <VerifyEmailAlert />

                    <PageTitle title="Dashboard" setOrganizationHandler={this.props.setOrganizationHandler} showSwitchDropdown={true}/>

                    {/* <WelcomeModal openModal={shouldDisplayWelcomeModal} /> */}

                    <IonRow>
                        <IonCol sizeMd="12">
                            <InvitationsCard />
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-align-items-stretch">
                        <IonCol sizeXs="12" sizeMd="6">
                            <IonCard color="white" className="ion-padding">
                                <IonCardTitle>
                                    <h3>Welcome to RingSide Pro!</h3>  
                                </IonCardTitle>
                                <IonCardContent>
                                    <p>Take a look around! We tailor your experience to your needs by using your roles. You can always update your roles in <a href="/index/settings">Settings</a>.</p>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="6">
                            <IonCard color="white" className="ion-padding">
                                <IonCardTitle>
                                    <h3>Explore Events</h3>
                                </IonCardTitle>
                                <IonCardContent>
                                    <p>Find horse shows, clinics and camps under events! You can sign up for text alerts, view the schedule, or enter online! <a href="/index/events">Find Events</a>.</p>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="6">
                            <IonCard color="white" className="ion-padding">
                                <IonCardTitle>
                                    <h3>Text Alerts</h3>
                                </IonCardTitle>
                                <IonCardContent>
                                    <p>Stay up to date! Get alerts about the events you attend.</p>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                            <a href="/index/events">
                                                <IonButton color="tertiary">
                                                    Get Alerts
                                                </IonButton>
                                            </a>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                            <a href="/index/alerts">
                                                <IonButton color="light">
                                                    Update Settings
                                                </IonButton>
                                            </a>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="6">
                            <IonCard color="white" className="ion-padding">
                                <IonCardTitle>
                                    <h3>Online Entries</h3>
                                </IonCardTitle>
                                <IonCardContent>
                                    <p>Find events that accept online entries through RingSide Pro.</p>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                            <a href="/index/events">
                                                <IonButton color="tertiary">
                                                    Find Events
                                                </IonButton>
                                            </a>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                            <a href="/index/entries">
                                                <IonButton color="light text-primary">
                                                    Your Entries
                                                </IonButton>
                                            </a>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol sizeMd="6">
                            <IonCard color="white" className="ion-padding">
                                <IonCardTitle>
                                    <h3>Want some help?</h3>
                                </IonCardTitle>
                                <IonCardContent>
                                    <p>Contact RingSide Pro customer service! We're always happy to help. Email us at hello@ringsidepro.com.</p>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                </IonContent>
            </IonPage>
        );
    }
}

export default DashboardPage;