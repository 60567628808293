import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import { Barn, Horse } from "../../models";
import { getAllHorses, getHorsesByBarnId } from "../../utilities/horse/Horse";
import Spinner from "../Spinners/Spinner";
const { v4: uuidv4 } = require('uuid');

interface _Props {
    selectedBarn?: (Barn | null)
    selectedValue?: (string | null)
    onSelect: Function
    isHeightRequired?: boolean
    height?: string
}

interface formattedOption {
    value: string //Horse's id
    label: string //Used for filtering options as a user types
    horseName: string //Horse's Show Name - will display when item is selected
    horseNameLabel?: string //Horse's Show Name + Nickname - will display in options
    barnLabel?: string //Barn Name - will display in options
    ownerLabel?: string //Date the horse was created - will display in options
    horse?: Horse
}

interface formattedGroupOfOptions {
    label: string
    options: formattedOption[]
}

/**
 * This function is passed into the select component to define what should be shown when an option is selected
 * Instead of showing the 3 lines from the option,
 * it will only show the 1 line defined below: horseName
 * @param props 
 * @returns 
 */
const SingleValue = (props: any) => (
    <components.SingleValue {...props}>
        {props.data.horseName || props.data.label}
    </components.SingleValue>
);

const SelectOrCreateHorse: React.FC<_Props> = ({selectedBarn, selectedValue, onSelect, isHeightRequired, height}) => {

    // We group the options into: 1-Currently Selected Barn's horses or 2-All Horses
    const [formattedBarnHorses, setFormattedBarnHorses] = useState<formattedOption[] | null | undefined>();
    const [formattedHorses, setFormattedHorses] = useState<formattedOption[] | null | undefined>();

    // We then put the formatted options into defined Groups
    const [barnHorsesGroup, setBarnHorsesGroup] = useState<formattedGroupOfOptions | null | undefined>();
    const [allHorsesGroup, setAllHorsesGroup] = useState<formattedGroupOfOptions | null | undefined>();

    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const sortHorseOptions = (horseArray: Horse[]) => {
        return horseArray.sort((a,b) => a.name.trim().localeCompare(b.name.trim()));
    }

    const removeBarnHorsesFromAllHorsesGroup = async (barnHorses: formattedOption[]) => {
        if (allHorsesGroup && formattedHorses) {
            const updatedFormattedHorses = formattedHorses.filter((el) => !barnHorses.find((bh => bh.value === el.value)));
            const allGroup: formattedGroupOfOptions = {
                label: "All Horses in RingSide Pro database",
                options: updatedFormattedHorses
            };
            setAllHorsesGroup(allGroup);
        }
    };

    const getBarnHorses = async () => {
        setIsLoading(true);

        // Get the current list of horses
        let currentHorseList: Horse[] = [];
        if (selectedBarn) {
            const queryHorsesByBarn = await getHorsesByBarnId(selectedBarn.id);
            if (queryHorsesByBarn.isSuccess) {
                currentHorseList = queryHorsesByBarn.result;
            }
        }

        // Sort and format the list
        const sortedHorseList = sortHorseOptions(currentHorseList);
        const currentFormattedHorses = await formatHorses(sortedHorseList);

        // Set the formatted options and group in state
        setFormattedBarnHorses(currentFormattedHorses);
        const barnGroup: formattedGroupOfOptions = {
            label: (selectedBarn?.name || "Barn") + " Horses",
            options: currentFormattedHorses
        };
        setBarnHorsesGroup(barnGroup);

        // Remove the selected barn's horses from the list of all horses
        removeBarnHorsesFromAllHorsesGroup(currentFormattedHorses);

        // Check if the current selected value from props is in this list
        if (selectedValue) findValueInList(selectedValue, currentFormattedHorses);

        setIsLoading(false);
    };

    const getHorses = async () => {
        setIsLoading(true);

        // Get the current list of horses
        let currentHorseList: Horse[] = [];
        const queryResult = await getAllHorses();
        if (queryResult.isSuccess) {
            currentHorseList = queryResult.result;
        }

        // Sort and format the list
        const sortedHorseList = sortHorseOptions(currentHorseList);
        const currentFormattedHorses = await formatHorses(sortedHorseList);
        
        // Set the formatted options and group in state
        setFormattedHorses(currentFormattedHorses);


        // If barn horses are already there, remove them from the full list and set All Horses Group
        if (formattedBarnHorses) {
            removeBarnHorsesFromAllHorsesGroup(formattedBarnHorses);
        } else {
            const allGroup: formattedGroupOfOptions = {
                label: "All Horses in RingSide Pro database",
                options: currentFormattedHorses
            };
            setAllHorsesGroup(allGroup);    
        }

        // If the parent component has passed in an already selected value, set it from the current options
        if (selectedValue) findValueInList(selectedValue, currentFormattedHorses);

        setIsLoading(false);
    };

    const formatSingleHorse = (horse: Horse) => {
        const value = horse.id;
        const name = horse.name;
        const nickname = horse.nickname || "";
        const horseNameLabel = (name || "error") + (nickname ? " (" + nickname +  ")" : "");
        const barnName = horse.barn?.name || horse.barnName || "unknown";
        const ownerLabel = horse.ownerName || horse.owner?.name || "unknown";
        let object: formattedOption = {
            value: value,
            label: horseNameLabel + " " + barnName,
            horseName: name,
            horseNameLabel: horseNameLabel,
            barnLabel: barnName,
            ownerLabel: ownerLabel,
            horse: horse
        };
        return object;
    }

    const formatHorses = async (horseArray: Horse[]) => {
        setIsLoading(true);

        let formattedHorses = [];

        for (var i = 0; i < horseArray.length; i++) {
            const horse = horseArray[i];
            let object: formattedOption = formatSingleHorse(horse);
            formattedHorses.push(object);
        }
       
        return formattedHorses;
    };

    const findValueInList = (value: string, horseList?: formattedOption[]) => {
        let optionArray = horseList || formattedHorses;
        if (optionArray) {
            for (var i = 0; i < optionArray.length; i++) {
                const currentOption = optionArray[i];
                if (currentOption.value === value) {
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    useEffect(() => {
        getHorses();
    }, []);

    useEffect(() => {
        getBarnHorses();
    }, [selectedBarn]);

    const handleOnChange = (event?: any) => {
        if (event && event.horse) {
            setCurrentValue(event);
            onSelect(event.horse);
        } else if (event && event.value) {
            // When a new option is selected from the "Create" option, it will only have a value
            if (event.horseName) {
                // This option was created by the user and then selected again
                setCurrentValue(event);
                onSelect(null, event.horseName);
            } else {
                // Format the new option
                const newFormattedOption: formattedOption = {
                    value: uuidv4(),
                    label: event.value + " " + (selectedBarn?.name || ""),
                    horseName: event.value,
                    horseNameLabel: event.value,
                    barnLabel: selectedBarn?.name || ""
                };

                if (selectedBarn) {
                    // If a barn is currently selected, then add the new option to the list of barn horses
                    const updatedFormattedBarnHorses = formattedBarnHorses ? formattedBarnHorses.concat([newFormattedOption]) : [newFormattedOption];
                    const sortedFormattedBarnHorses = updatedFormattedBarnHorses.sort((a,b) => a.horseName.trim().localeCompare(b.horseName.trim()));
                    setFormattedBarnHorses(updatedFormattedBarnHorses);
                    const barnGroup: formattedGroupOfOptions = {
                        label: (selectedBarn?.name || "Barn") + " Horses",
                        options: sortedFormattedBarnHorses
                    };
                    setBarnHorsesGroup(barnGroup);
                } else {
                    // Add the horse to the full list of options
                    const updatedFormattedHorses = formattedHorses ? formattedHorses.concat([newFormattedOption]) : [newFormattedOption];
                    setFormattedHorses(updatedFormattedHorses);
                    const allGroup: formattedGroupOfOptions = {
                        label: "All Horses in RingSide Pro database",
                        options: updatedFormattedHorses
                    };
                    setAllHorsesGroup(allGroup);
                }
                // Set this option as the currently selected option
                setCurrentValue(newFormattedOption);
                console.log("send back event.value: ", event.value);
                onSelect(null, event.value);
            }
        } else {
            setCurrentValue(null);
            onSelect(null);
        }
    }

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <>
                    {(barnHorsesGroup && allHorsesGroup) && (
                        <CreatableSelect
                            id="select-or-create-horse"
                            inputId="select-or-create-horse-input"
                            placeholder="Type the horse's name"
                            styles={{
                                // Fixes the overlapping problem of the component
                                control: baseStyles =>(isHeightRequired ? {...baseStyles, height} : {...baseStyles}),
                                menu: provided => ({ ...provided, zIndex: 9999 }),
                            }}
                            defaultValue={currentValue}
                            value={currentValue}
                            menuPortalTarget={document.body}
                            isClearable
                            options={[
                                barnHorsesGroup,
                                allHorsesGroup
                            ]}
                            formatOptionLabel={(option: any) => (
                                option.horseNameLabel ?
                                (
                                    <div className="p-0">
                                        <div className="font-weight-bold text-default">{option.horseNameLabel}</div>
                                        <div className="display-5 text-medium">Barn: {option.barnLabel}</div>
                                        <div className="display-5 text-medium">Owner: {option.ownerLabel}</div>
                                    </div>
                                )
                                :
                                (
                                    <div className="p-0">
                                        <div className="font-weight-bold text-default">Add New Horse: {option.value}</div>
                                        <div className="display-5 text-medium">To Barn: {selectedBarn ? selectedBarn.name : "None Selected"}</div>
                                    </div>
                                )
                            )}
                            components={{ SingleValue }}
                            onChange={(event: any) => handleOnChange(event)}
                            createOptionPosition={"last"}
                        />
                    )}
                </>
            }
        </>
    )
};

export default SelectOrCreateHorse;
